<template>
  <div class="main">
<!--    <el-button type="primary" class="btn no-print" icon="printer" @click="print">打印</el-button>-->
    <ul>
      <li>{{ t('printable.ofWhatDevice') }}{{ store.state.printable.name }}（{{ store.state.printable.model }}）</li>
      <li>{{ t('printable.ofWhatCable') }}{{ store.state.printable.cable }}</li>
      <li>{{ t('printable.ofWhatBox') }}{{ store.state.printable.box }}（{{ store.state.printable.boxModel }}）</li>
    </ul>
    <br>
    <template v-if="store.state.printable.alarms.length > 0">
      <p>设备告警表：</p>
      <br>
      <table>
        <tr>
          <th>告警项</th>
          <th>告警值</th>
          <th>累计次数</th>
          <th>最近告警时间</th>
          <th>首次告警时间</th>
          <th>确认时间</th>
          <th>状态</th>
        </tr>
        <tr v-for="al in store.state.printable.alarms" :key="al.id">
          <td>{{ al.name }}</td>
          <td>{{ al.value + al.unit }}</td>
          <td>{{ al.frequency }}</td>
          <td>{{ formatTimeToStr(al.updated) }}</td>
          <td>{{ formatTimeToStr(al.created) }}</td>
          <td>{{ al.state > 1 ? formatTimeToStr(al.confirmed) : '' }}</td>
          <td>{{ al.state > 1 ? '已确认未处理' : '未确认' }}</td>
        </tr>
      </table>
    </template>
    <p v-else>该设备当前无告警。</p>
    <br>
    <p>从 {{ start }} 到 {{ end }} 的数据曲线：</p>
    <img :src="store.state.printable.dataUrl" class="img"/>


  </div>
</template>

<script setup>
import {formatTimeToStr} from "@/utils/date"
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

function format(time) {
  return formatTimeToStr(time, 'yyyy年MM月dd日 hh:mm:ss')
}

const start = format(store.state.printable.start)
const end = format(store.state.printable.end)

function print() {
  window.print()
}

setTimeout(()=>{
  print()
}, 500)

</script>

<style scoped>
.main {
  font-family: "Times New Roman", "宋体", serif;
  justify-self: center;
  width: 21cm;
  padding: 0.5in 0.60in 0.8in 0.60in;
  background-color: white;
}

.img {
  position: relative;
  left: -1cm;
  padding-top: 30px;
  width: 19cm;
}

table {
  font-size: 12px;
  line-height: 15px;
  /*font-family: arial, sans-serif;*/
  border-collapse: collapse;
  width: 90%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
}

/*tr:nth-child(even) {*/
/*  background-color: #dddddd;*/
/*}*/

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}

.btn {
  /*color: black;*/
}
</style>

<style>
#app {
  overflow: auto !important;
  background: none !important;
  color: black !important;
}

body {
  display: flex;
  justify-content: center;
}
</style>